import cx from 'clsx';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import type { ModalOverlayProps as AriaModalProps } from 'react-aria-components';
import { Modal } from 'react-aria-components';
import { CloseIcon } from '../../assets/icons';
import { Badge, type BadgeProps } from '../badge';
import { Button } from '../button';
import styles from './popup.strict-module.css';

interface BaseProps extends AriaModalProps {
  title: string;
  subtitle?: string;
  illustration?: ReactNode;
  badge?: BadgeProps;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  closeClassName?: string;
  confirmActionLoading?: boolean;
}

interface NormalPopupProps extends BaseProps {
  type?: 'confirm' | 'destructive';
  onCustomCancelCta?: never;
}

interface PromotionalPopupProps extends BaseProps {
  type: 'promotional';
  onCustomCancelCta?: () => void; // TODO: Remove this prop when the Regate integration is complete.
}

interface InfoPopupProps extends BaseProps {
  type: 'info';
  onConfirm?: never;
  confirmText?: never;
  cancelText?: never;
  footerClassName?: never;
  onCustomCancelCta?: never;
}

export type PopupProps = PropsWithChildren<
  NormalPopupProps | PromotionalPopupProps | InfoPopupProps
>;

export function Popup({
  title,
  subtitle,
  type = 'confirm',
  illustration,
  badge,
  onConfirm,
  onOpenChange,
  onCancel = () => {
    onOpenChange?.(false);
  },
  confirmText,
  cancelText,
  onCustomCancelCta,
  children,
  className,
  headerClassName,
  bodyClassName,
  footerClassName,
  closeClassName,
  confirmActionLoading,
  ...props
}: PopupProps): ReactElement {
  return (
    <Modal {...props} className={cx(styles.wrapper, className)}>
      <Button
        aria-label="Close"
        className={cx(styles.close, closeClassName)}
        data-test-id="modal-close-button"
        iconOnly
        onPress={onCancel}
        type="button"
        variant="tertiary"
      >
        <CloseIcon aria-hidden />
      </Button>
      {illustration ? (
        <div className={cx(type === 'promotional' && styles.promotionalIllustration)}>
          {illustration}
        </div>
      ) : null}
      {badge ? (
        <div className={styles.badge}>
          <Badge {...badge} />
        </div>
      ) : null}
      <div className={cx(styles.header, headerClassName)}>
        <h1 className={cx(styles.title, 'title-2')} data-test-id="modal-title">
          {title}
        </h1>
        {Boolean(subtitle) && <h2 className={cx(styles.subtitle, 'body-1')}>{subtitle}</h2>}
      </div>
      {Boolean(children) && <div className={cx(styles.body, bodyClassName)}>{children}</div>}
      {type !== 'info' && (
        <div className={cx(styles.footer, footerClassName)}>
          {Boolean(cancelText) && (
            <Button
              aria-label="Cancel"
              onPress={type === 'promotional' && onCustomCancelCta ? onCustomCancelCta : onCancel}
              variant="secondary"
            >
              {cancelText}
            </Button>
          )}
          <Button
            aria-label="Confirm"
            data-test-id="modal-confirm-button"
            isLoading={confirmActionLoading}
            onPress={onConfirm}
            variant={type === 'destructive' ? 'danger' : 'primary'}
          >
            {confirmText}
          </Button>
        </div>
      )}
    </Modal>
  );
}
