import type { ReactElement, SVGProps } from 'react';

export function BadgeCone(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M12 11.107h-.13l-3.01-7.87c-.28-.85-1.51-.85-1.79 0l-2.95 7.87H4c-.55 0-1 .44-1 1s.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1m-4-5.75c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1m-2.32 4.97c0-.43.35-.78.78-.78h3.08a.781.781 0 0 1 0 1.56H6.46c-.43 0-.78-.35-.78-.78"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
