import type { SVGProps, ReactElement } from 'react';

export function IconCheckmarkRoundedFilledSmall(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={12} width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12Zm2.824-7.235a.5.5 0 1 0-.707-.707L5.294 6.881 3.882 5.47a.5.5 0 1 0-.707.707L4.94 7.942a.5.5 0 0 0 .707 0l3.177-3.177Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
