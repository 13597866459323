import type { SVGProps, ReactElement } from 'react';

export function IconErrorFilledSmall(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={12} width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M11.77 5.43 6.57.233A.806.806 0 0 0 6 0a.806.806 0 0 0-.57.233L.23 5.43a.82.82 0 0 0 0 1.14l5.2 5.197A.806.806 0 0 0 6 12a.806.806 0 0 0 .57-.232l5.2-5.198a.82.82 0 0 0 0-1.14ZM5.5 3.464a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3ZM6 9.036a.65.65 0 1 0 0-1.3.65.65 0 0 0 0 1.3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
