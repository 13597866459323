import cx from 'clsx';
import type { ReactElement } from 'react';
import styles from './skeleton-loader.strict-module.css';

interface HeaderProps {
  width?: string;
  lines?: number;
  smallLinesWidth?: string;
}

function Header({ width, lines = 0, smallLinesWidth = '75%' }: HeaderProps): ReactElement {
  const className = cx(styles.base, styles.line);

  return (
    <div className={styles.header}>
      <div className={className} style={{ width }} />
      {lines > 0 &&
        Array.from({ length: lines }, (_, index) => (
          <div
            className={className}
            data-test-placeholder-header-line
            key={index}
            style={{ width: smallLinesWidth }}
          />
        ))}
    </div>
  );
}

interface SkeletonLoaderWrapperProps {
  standalone?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  lines?: number;
  smallLinesWidth?: string;
}

export interface SkeletonLoaderProps extends SkeletonLoaderWrapperProps {
  type?: 'avatar' | 'block' | 'line' | 'header';
}

export function SkeletonLoaderComponent({
  type = 'block',
  standalone = false,
  width,
  height,
  borderRadius,
  lines,
  smallLinesWidth,
}: SkeletonLoaderProps): ReactElement {
  if (type === 'header') {
    return <Header lines={lines} smallLinesWidth={smallLinesWidth} width={width} />;
  }

  const className = cx(styles.base, styles[type], standalone && styles.standalone);

  return (
    <div
      className={className}
      style={{
        width,
        height,
        borderRadius,
      }}
    />
  );
}

function SkeletonLoaderLine({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="line" {...props} />;
}

function SkeletonLoaderBlock({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="block" {...props} />;
}

function SkeletonLoaderHeader({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="header" {...props} />;
}

function SkeletonLoaderAvatar({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="avatar" {...props} />;
}

export const SkeletonLoader = {
  Line: SkeletonLoaderLine,
  Block: SkeletonLoaderBlock,
  Header: SkeletonLoaderHeader,
  Avatar: SkeletonLoaderAvatar,
};
