import { type ReactElement } from 'react';
import type {
  DatePickerProps as AriaDatePickerProps,
  DateValue,
  ValidationResult,
} from 'react-aria-components';
import { DatePicker as AriaDatePicker, Dialog, Group, Popover, Text } from 'react-aria-components';
import { Button } from '../button';
import { DateField } from '../date-field';
import { Label } from '../form-elements';
import { Calendar } from './calendar';
import styles from './date-picker.strict-module.css';
import { IconCalendarOutlined } from './icon/icon-calendar-outlined';

interface DatePickerProps<T extends DateValue> extends AriaDatePickerProps<T> {
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  label?: string;
}

export function DatePicker<T extends DateValue>({
  description,
  errorMessage,
  label,
  ...props
}: DatePickerProps<T>): ReactElement {
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  return (
    <AriaDatePicker className={styles.wrapper} {...props}>
      <Label>{label}</Label>
      <Group className={styles.group}>
        <DateField
          className={styles.datefield}
          data-test-date-picker-input
          errorMessage={errorMessage}
        />
        <Button
          className={styles.calendarIcon}
          data-test-date-picker-calendar-button
          iconOnly
          size="small"
          variant="tertiary"
        >
          <IconCalendarOutlined />
        </Button>
      </Group>
      {description ? <Text slot="description">{description}</Text> : null}
      <Popover UNSTABLE_portalContainer={PORTAL_CONTAINER}>
        <Dialog>
          <Calendar data-testid="calendar" />
        </Dialog>
      </Popover>
    </AriaDatePicker>
  );
}
