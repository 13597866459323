import type { SVGProps, ReactElement } from 'react';

export function IconIsometricFlagEs(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height="20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.985 7.846 15 11.382 1.387 19l-.014-3.537 13.612-7.617ZM1.373 15.463l-1.354-.758 13.618-7.622 1.348.763-13.612 7.617Z"
        fill="#E02D2D"
      />
      <path
        d="m14.976 4.3.02 3.532-13.623 7.627-.01-3.537L14.976 4.3ZM1.363 11.922.01 11.16 13.622 3.54l1.354.759-13.613 7.622Z"
        fill="#FFD000"
      />
      <path d="m14.966.763.01 3.537-13.613 7.622-.01-3.541L14.966.763Z" fill="#E02D2D" />
      <path
        d="m1.373 14.998-.01-3.076-.01-3.541L14.966.763 13.613 0 0 7.618l.01 3.541.01 3.536h.004l.01 3.546L1.387 19l-.014-4.002Z"
        fill="#1D1D1B"
      />
      <path
        d="M5.848 5.926 14.92.85l.034 10.503-9.128 5.11-4.391 2.454-.029-10.502 4.443-2.49Z"
        stroke="#1D1D1B"
      />
    </svg>
  );
}
