import type { SVGProps, ReactElement } from 'react';

export function IconProcessingFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={12} width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M6 0C2.685 0 0 2.685 0 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6ZM2.891 5.513c.042-.844.499-1.613 1.249-2.108 1.185-.78 2.831-.664 3.874.263l.345-.226a.49.49 0 0 1 .712.214c.038.087.049.18.034.274l-.18 1.061a.482.482 0 0 1-.548.402L7.2 5.228a.486.486 0 0 1-.413-.375.475.475 0 0 1 .027-.29.473.473 0 0 1 .183-.224l.158-.105c-.72-.491-1.736-.503-2.479-.015-.487.322-.784.813-.81 1.342v.056a.532.532 0 0 1-.146.342.506.506 0 0 1-.341.146h-.015a.475.475 0 0 1-.476-.473v-.12h.003Zm6.218.97C9.068 7.332 8.61 8.1 7.86 8.596a3.205 3.205 0 0 1-1.744.518h-.015a3.21 3.21 0 0 1-2.115-.78l-.341.224a.51.51 0 0 1-.27.083.495.495 0 0 1-.45-.296.468.468 0 0 1-.03-.278l.18-1.057a.482.482 0 0 1 .547-.402l1.182.162c.097.015.187.06.262.127a.497.497 0 0 1 .12.54.511.511 0 0 1-.183.225l-.158.105c.72.488 1.736.503 2.479.015.487-.322.784-.813.813-1.346v-.056a.508.508 0 0 1 .495-.487.482.482 0 0 1 .48.487v.105H9.11Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
