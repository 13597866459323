import type { SVGProps, ReactElement } from 'react';

export function IconIsometricFlagDe(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height="20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m14.99 7.846.01 3.536L1.383 19l-.01-3.537L14.99 7.846Z" fill="#FFD000" />
      <path d="M1.373 15.463.02 14.7l13.617-7.617 1.354.763-13.617 7.617Z" fill="#FFD000" />
      <path d="M14.98 4.3 15 7.836 1.373 15.46l-.01-3.537L14.982 4.3Z" fill="#E02D2D" />
      <path d="M1.364 11.922.01 11.16l13.617-7.622 1.354.763-13.617 7.622Z" fill="#E02D2D" />
      <path
        d="m14.971.763.01 3.537-13.617 7.622-.01-3.541L14.971.763ZM.02 14.7l.009 3.537L1.383 19l-.01-3.537L.02 14.7Z"
        fill="#1D1D1B"
      />
      <path
        d="m1.373 15.459-.01-3.537-.009-3.541L14.971.763 13.617 0 0 7.618l.01 3.54.01 3.537 1.353.764Z"
        fill="#1D1D1B"
      />
      <path
        d="M5.85 5.926 14.924.85l.034 10.503-9.13 5.11-4.393 2.454-.029-10.502 4.444-2.49Z"
        stroke="#1D1D1B"
      />
    </svg>
  );
}
