import type { ReactElement } from 'react';
import type {
  ValidationResult,
  DateFieldProps as AriaDateFieldProps,
  DateValue} from 'react-aria-components';
import {
  DateField as AriaDateField,
  DateSegment,
} from 'react-aria-components';
import cx from 'clsx';
import { Description, Error, Label, DateInput } from '../form-elements';
import styles from './date-field.strict-module.css';

interface DateFieldProps<T extends DateValue> extends AriaDateFieldProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function DateField<T extends DateValue>({
  label,
  description,
  errorMessage,
  className,
  isReadOnly,
  ...props
}: DateFieldProps<T>): ReactElement {
  return (
    <AriaDateField className={cx(className, styles.container)} isReadOnly={isReadOnly} {...props}>
      {Boolean(label) && <Label>{label}</Label>}
      <DateInput className={cx(isReadOnly && styles.readonly)}>
        {segment => <DateSegment segment={segment} />}
      </DateInput>
      <Error>{errorMessage}</Error>
      {Boolean(description) && <Description>{description}</Description>}
    </AriaDateField>
  );
}
