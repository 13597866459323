import type { RadioProps } from 'react-aria-components';
import { Radio } from 'react-aria-components';
import type { ReactElement } from 'react';
import cx from 'clsx';
import styles from './radio-button.strict-module.css';

export function RadioButton({ className, ...props }: RadioProps): ReactElement {
  return (
    <Radio className={cx(styles.radio, 'body-1', className)} {...props} data-test-radio-button />
  );
}
