import type { ReactElement } from 'react';
import type { SwitchProps as AriaSwitchProps } from 'react-aria-components';
import { Switch } from 'react-aria-components';
import cx from 'clsx';
import styles from './toggle.strict-module.css';

interface SwitchProps extends AriaSwitchProps {
  checked?: boolean;
  className?: string;
}

export function Toggle({ checked, className, ...props }: SwitchProps): ReactElement {
  return (
    <Switch className={styles.wrapper} defaultSelected={checked} {...props}>
      <div className={cx(styles.toggle, className)}>
        <div className={styles.knob} />
        <div className={styles.overlay} />
      </div>
    </Switch>
  );
}
