import type { ReactElement, SVGProps } from 'react';

export function BadgeStars(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.12 5.203 9.5 7.25l-.62-2.048-1.63-.577 1.63-.577L9.5 2l.62 2.048 1.63.577zM5.375 14l.926-3.51L8.75 9.5 6.3 8.51 5.376 5l-.926 3.51L2 9.5l2.45.99zM12.913 10.288 12.5 11.75l-.413-1.463L11 9.875l1.087-.412L12.5 8l.413 1.463L14 9.875z"
        fill="currentColor"
      />
    </svg>
  );
}
