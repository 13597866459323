import type { ComponentPropsWithoutRef, ReactElement } from 'react';

export function BadgeArrow(props: ComponentPropsWithoutRef<'svg'>): ReactElement {
  return (
    <svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="m13.694 5.64-2.4-1.37a.67.67 0 0 0-.37-.08l-7.12.86c-.33.04-.57.34-.53.66l.07.59c.57-.07 1.09.34 1.17.91.07.58-.33 1.1-.91 1.18l.07.6c.04.33.34.56.67.52l2.92-.35v4.3c0 .3.24.54.54.54h.92c.3 0 .54-.24.54-.54V8.92l2.2-.26a.6.6 0 0 0 .34-.16l2.01-1.9a.61.61 0 0 0-.11-.96zM9.264 3.014c0-.55-.45-1-1-1s-1 .45-1 1v.65l2-.24z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
