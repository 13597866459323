import { useLayoutEffect } from 'react';
import { isTesting } from '../../../utils/env';

const injectCss = (): HTMLStyleElement => {
  const styleElement = document.createElement('style');
  styleElement.setAttribute('data-test-fourthline-embedded-web-sdk-css', '');
  styleElement.textContent = `
    fl-flow-onboarding {
      --fl-qr-border-radius: 2px;
      --fl-qr-border-color: transparent;
      --fl-qr-background-color: var(--gray200);
      --fl-internal-background-color: var(--content-primary-b);
      --fl-input-background-color: var(--content-primary-b);
      --fl-tab-underline-color: var(--fl-color-dark);
      --fl-color-primary: var(--content-primary-a);
      --fl-color-accent: var(--content-accent);
      --fl-color-dark: var(--content-primary-a);
      --fl-color-dark-light: var(--content-secondary);
      --fl-color-dark-lighter: var(--content-tertiary);
      --fl-color-light: var(--content-primary-b);
      --fl-color-gray: #B0BAC9;
      --fl-color-info: var(--content-accent);
      --fl-color-success: var(--content-success);
      --fl-color-hint: #FFF9D5;
      --fl-color-warning: var(--warning400);
      --fl-color-danger: var(--error400);
      --fl-background-color: transparent;
      --fl-phone-selected-background-color: var(--purple50);
    }

    .dark-theme fl-flow-onboarding {
      --fl-decorative-line-color: var(--background-secondary);
      --fl-qr-background-color: var(--background-tertiary);
    }
   `;
  document.head.appendChild(styleElement);

  return styleElement;
};

const injectJs = (): [HTMLScriptElement, HTMLScriptElement] => {
  const esmScriptElement = document.createElement('script');

  if (!isTesting) {
    if (process.env.FOURTHLINE_WEBSDK_ESM_URL) {
      esmScriptElement.src = process.env.FOURTHLINE_WEBSDK_ESM_URL;
    }
  }

  esmScriptElement.type = 'module';
  esmScriptElement.setAttribute('data-test-fourthline-embedded-web-sdk-js-esm', '');
  document.head.appendChild(esmScriptElement);

  const scriptElement = document.createElement('script');

  if (!isTesting) {
    if (process.env.FOURTHLINE_WEBSDK_DEFAULT_URL) {
      scriptElement.src = process.env.FOURTHLINE_WEBSDK_DEFAULT_URL;
    }
  }

  scriptElement.setAttribute('nomodule', '');
  scriptElement.setAttribute('data-test-fourthline-embedded-web-sdk-js', '');
  document.head.appendChild(scriptElement);

  return [esmScriptElement, scriptElement];
};

export function useMeta(): void {
  useLayoutEffect(() => {
    const styleElement = injectCss();
    const [esmScriptElement, scriptElement] = injectJs();

    return () => {
      [esmScriptElement, scriptElement, styleElement].forEach(element => {
        if (document.head.contains(element)) {
          document.head.removeChild(element);
        }
      });
    };
  }, []);
}
