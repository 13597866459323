import type { ReactElement, SVGProps } from 'react';

export function IconArrowSmallRightFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m6.614 4.15 3.415 3.322a.749.749 0 0 1 0 1.059L6.614 11.85a.5.5 0 0 1-.715 0 .5.5 0 0 1-.146-.352l-.001-6.994a.499.499 0 0 1 .147-.354.5.5 0 0 1 .715 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
