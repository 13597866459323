import type { ReactElement, ReactNode } from 'react';
import type { CheckboxProps as AriaCheckboxProps } from 'react-aria-components';
import { Checkbox as AriaCheckbox } from 'react-aria-components';
import cx from 'clsx';
import { WarningSign } from '../../assets/icons';
import { CheckmarkOutlinedCheckboxIcon } from './icons/checkmark-outlined-checkbox';
import { MinusOutlinedCheckboxIcon } from './icons/minus-outlined-checkbox';
import styles from './checkbox.strict-module.css';

interface CheckboxProps extends AriaCheckboxProps {
  children?: ReactNode;
  errorMessage?: string;
}

export function Checkbox({
  className,
  children,
  errorMessage,
  ...props
}: CheckboxProps): ReactElement {
  return (
    <AriaCheckbox
      className={cx(
        className,
        styles['checkbox-wrapper'],
        errorMessage ? null : styles['checkbox-wrapper-error'],
        children ? null : styles['checkbox-simple']
      )}
      data-test-aria-checkbox=""
      {...props}
    >
      {({ isSelected, isIndeterminate, isInvalid }) => {
        let icon = null;
        if (isSelected) {
          icon = <CheckmarkOutlinedCheckboxIcon />;
        }

        if (isIndeterminate) {
          icon = <MinusOutlinedCheckboxIcon />;
        }

        return (
          <div className={styles['checkbox-container']} role="presentation">
            <div className={styles['checkbox-row']}>
              <div
                className={cx('overlay', styles.checkbox, children ? styles['mt-4'] : null)}
                data-checkbox
                data-test-checkbox=""
              >
                {icon}
              </div>
              {children ? (
                <span className={cx('body-2', 'ml-8', styles.label)} data-label data-test-label="">
                  {children}
                </span>
              ) : null}
            </div>
            {isInvalid && errorMessage ? (
              <div className={styles.error} data-test-error-label="" role="alert">
                <WarningSign className="mr-8" />
                {errorMessage}
              </div>
            ) : null}
          </div>
        );
      }}
    </AriaCheckbox>
  );
}
