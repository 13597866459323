import type { ReactElement, ComponentPropsWithoutRef } from 'react';
import cx from 'clsx';
import styles from './avatar.strict-module.css';

type AvatarSize = '64' | '56' | '48' | '40' | '32' | '30' | '24' | '16';

interface AvatarProps extends ComponentPropsWithoutRef<'img'> {
  size?: AvatarSize;
  withBorder?: boolean;
  className?: string;
}

export function Avatar({
  loading = 'eager',
  alt = '',
  size = '32',
  withBorder = false,
  className,
  ...props
}: AvatarProps): ReactElement {
  return (
    <div
      className={cx(
        styles['avatar-wrapper'],
        styles[`size-${size}`],
        withBorder && styles['avatar-borders']
      )}
    >
      <img
        alt={alt}
        className={cx(styles.avatar, className)}
        data-test-avatar
        loading={loading}
        {...props}
      />
    </div>
  );
}
